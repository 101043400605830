@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?356nu3');
  src:  url('fonts/icomoon.eot?356nu3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?356nu3') format('truetype'),
    url('fonts/icomoon.woff?356nu3') format('woff'),
    url('fonts/icomoon.svg?356nu3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-icon-55:before {
  content: "\e9ff";
}
.icon-icon-56:before {
  content: "\ea00";
}
.icon-close:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-qr-download:before {
  content: "\ea01";
}
.icon-qr-send:before {
  content: "\ea02";
}
.icon-qrcode:before {
  content: "\f107";
}
.icon-download:before {
  content: "\f108";
}
.icon-close-2:before {
  content: "\e902";
}
.icon-back:before {
  content: "\e903";
}
.icon-info:before {
  content: "\e904";
}
.icon-done:before {
  content: "\e905";
}
.icon-question:before {
  content: "\e906";
}
.icon-alert:before {
  content: "\e907";
}
.icon-danger:before {
  content: "\e908";
}
.icon-toggle:before {
  content: "\e909";
}
.icon-exit:before {
  content: "\e90a";
}
.icon-inside:before {
  content: "\e90b";
}
.icon-badget:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
.icon-smiley-face:before {
  content: "\e90e";
}
.icon-circle-dots:before {
  content: "\e90f";
}
.icon-th-large:before {
  content: "\e910";
}
.icon-hamburguer:before {
  content: "\e911";
}
.icon-signal:before {
  content: "\e912";
}
.icon-th:before {
  content: "\e913";
}
.icon-list:before {
  content: "\e914";
}
.icon-home:before {
  content: "\e915";
}
.icon-lantern:before {
  content: "\e916";
}
.icon-shield-key:before {
  content: "\e917";
}
.icon-shield-star:before {
  content: "\e918";
}
.icon-shield-ok:before {
  content: "\e919";
}
.icon-shield-reload:before {
  content: "\e91a";
}
.icon-shield-alt:before {
  content: "\e91b";
}
.icon-add-list:before {
  content: "\e91c";
}
.icon-scissors:before {
  content: "\e91d";
}
.icon-paste:before {
  content: "\e91e";
}
.icon-copy:before {
  content: "\e91f";
}
.icon-new-page:before {
  content: "\e920";
}
.icon-folder:before {
  content: "\e921";
}
.icon-folder-open:before {
  content: "\e922";
}
.icon-mouse:before {
  content: "\e923";
}
.icon-pointer:before {
  content: "\e924";
}
.icon-ppointer-click:before {
  content: "\e925";
}
.icon-thumbs-up:before {
  content: "\e926";
}
.icon-thumbs-down:before {
  content: "\e927";
}
.icon-FLexy-Icon-Set---20210129-40:before {
  content: "\e928";
}
.icon-user:before {
  content: "\e929";
}
.icon-add-user:before {
  content: "\e92a";
}
.icon-users:before {
  content: "\e92b";
}
.icon-edit-user:before {
  content: "\e92c";
}
.icon-lock:before {
  content: "\e92d";
}
.icon-lock-open:before {
  content: "\e92e";
}
.icon-eye:before {
  content: "\e92f";
}
.icon-eye-close:before {
  content: "\e930";
}
.icon-key-2:before {
  content: "\e931";
}
.icon-key:before {
  content: "\e932";
}
.icon-ring-bell:before {
  content: "\e933";
}
.icon-bell:before {
  content: "\e934";
}
.icon-bell-silence:before {
  content: "\e935";
}
.icon-slider-x:before {
  content: "\e936";
}
.icon-slider-y:before {
  content: "\e937";
}
.icon-graph:before {
  content: "\e938";
}
.icon-graph-2:before {
  content: "\e939";
}
.icon-gift:before {
  content: "\e93a";
}
.icon-filter:before {
  content: "\e93b";
}
.icon-offer:before {
  content: "\e93c";
}
.icon-phone:before {
  content: "\e93d";
}
.icon-phone-ring:before {
  content: "\e93e";
}
.icon-phone-cancel:before {
  content: "\e93f";
}
.icon-calendar:before {
  content: "\e940";
}
.icon-expand:before {
  content: "\e941";
}
.icon-reload-bag:before {
  content: "\e942";
}
.icon-clock:before {
  content: "\e943";
}
.icon-rotate:before {
  content: "\e944";
}
.icon-plane:before {
  content: "\e945";
}
.icon-world:before {
  content: "\e946";
}
.icon-bag:before {
  content: "\e947";
}
.icon-location:before {
  content: "\e948";
}
.icon-plane-2:before {
  content: "\e949";
}
.icon-icon-1:before {
  content: "\e94a";
}
.icon-icon-2:before {
  content: "\e94b";
}
.icon-route:before {
  content: "\e94c";
}
.icon-chinese:before {
  content: "\e94d";
}
.icon-bookmark:before {
  content: "\e94e";
}
.icon-heart:before {
  content: "\e94f";
}
.icon-mobile:before {
  content: "\e950";
}
.icon-volume:before {
  content: "\e951";
}
.icon-mute:before {
  content: "\e952";
}
.icon-pause:before {
  content: "\e953";
}
.icon-rewind:before {
  content: "\e954";
}
.icon-play:before {
  content: "\e955";
}
.icon-foward:before {
  content: "\e956";
}
.icon-microphone-alt:before {
  content: "\e957";
}
.icon-shopping-bag:before {
  content: "\e958";
}
.icon-cart:before {
  content: "\e959";
}
.icon-game:before {
  content: "\e95a";
}
.icon-trash:before {
  content: "\e95b";
}
.icon-plane-3:before {
  content: "\e95c";
}
.icon-disc:before {
  content: "\e95d";
}
.icon-certificate:before {
  content: "\e95e";
}
.icon-configuration:before {
  content: "\e95f";
}
.icon-comments:before {
  content: "\e960";
}
.icon-edit:before {
  content: "\e961";
}
.icon-clone:before {
  content: "\e962";
}
.icon-comment-dots:before {
  content: "\e963";
}
.icon-comments-alt:before {
  content: "\e964";
}
.icon-cd-playing:before {
  content: "\e965";
}
.icon-cd:before {
  content: "\e966";
}
.icon-music:before {
  content: "\e967";
}
.icon-share:before {
  content: "\e968";
}
.icon-play-list:before {
  content: "\e969";
}
.icon-envelote:before {
  content: "\e96a";
}
.icon-envelope-open:before {
  content: "\e96b";
}
.icon-reply:before {
  content: "\e96c";
}
.icon-camera:before {
  content: "\e96d";
}
.icon-record:before {
  content: "\e96e";
}
.icon-portrait:before {
  content: "\e96f";
}
.icon-icon-3:before {
  content: "\e970";
}
.icon-fingerprint:before {
  content: "\e971";
}
.icon-icon-4:before {
  content: "\e972";
}
.icon-bluetooth:before {
  content: "\e973";
}
.icon-compass:before {
  content: "\e974";
}
.icon-life-ring:before {
  content: "\e975";
}
.icon-router:before {
  content: "\e976";
}
.icon-vibration:before {
  content: "\e977";
}
.icon-icon-5:before {
  content: "\e978";
}
.icon-play-video:before {
  content: "\e979";
}
.icon-zoom:before {
  content: "\e97a";
}
.icon-zoom-2:before {
  content: "\e97b";
}
.icon-zoom-in:before {
  content: "\e97c";
}
.icon-zoom-out:before {
  content: "\e97d";
}
.icon-to-cloud:before {
  content: "\e97e";
}
.icon-from-cloud:before {
  content: "\e97f";
}
.icon-cloud:before {
  content: "\e980";
}
.icon-map-pin:before {
  content: "\e981";
}
.icon-icon-6:before {
  content: "\e982";
}
.icon-icon-7:before {
  content: "\e983";
}
.icon-signature:before {
  content: "\e984";
}
.icon-speed:before {
  content: "\e985";
}
.icon-expand-alt:before {
  content: "\e986";
}
.icon-reload-alt:before {
  content: "\e987";
}
.icon-icon-8:before {
  content: "\e988";
}
.icon-icon-9:before {
  content: "\e989";
}
.icon-icon-10:before {
  content: "\e98a";
}
.icon-icon-11:before {
  content: "\e98b";
}
.icon-edit-alt:before {
  content: "\e98c";
}
.icon-edit-box:before {
  content: "\e98d";
}
.icon-icon-12:before {
  content: "\e98e";
}
.icon-icon-13:before {
  content: "\e98f";
}
.icon-wallet:before {
  content: "\e990";
}
.icon-icon-14:before {
  content: "\e991";
}
.icon-ruler-combined:before {
  content: "\e992";
}
.icon-store:before {
  content: "\e993";
}
.icon-diamond:before {
  content: "\e994";
}
.icon-crown:before {
  content: "\e995";
}
.icon-share-alt:before {
  content: "\e996";
}
.icon-icon-15:before {
  content: "\e997";
}
.icon-basket:before {
  content: "\e998";
}
.icon-icon-16:before {
  content: "\e999";
}
.icon-icon-17:before {
  content: "\e99a";
}
.icon-icon-18:before {
  content: "\e99b";
}
.icon-sun:before {
  content: "\e99c";
}
.icon-image:before {
  content: "\e99d";
}
.icon-images:before {
  content: "\e99e";
}
.icon-paint-brush:before {
  content: "\e99f";
}
.icon-brush:before {
  content: "\e9a0";
}
.icon-books:before {
  content: "\e9a1";
}
.icon-open-book:before {
  content: "\e9a2";
}
.icon-book:before {
  content: "\e9a3";
}
.icon-Flag:before {
  content: "\e9a4";
}
.icon-fast-backward:before {
  content: "\e9a5";
}
.icon-fast-forward:before {
  content: "\e9a6";
}
.icon-puzzle:before {
  content: "\e9a7";
}
.icon-icon-19:before {
  content: "\e9a8";
}
.icon-stopwatch:before {
  content: "\e9a9";
}
.icon-hourglass-start:before {
  content: "\e9aa";
}
.icon-icon-20:before {
  content: "\e9ab";
}
.icon-icon-21:before {
  content: "\e9ac";
}
.icon-icon-22:before {
  content: "\e9ad";
}
.icon-icon-23:before {
  content: "\e9ae";
}
.icon-toggle-alt:before {
  content: "\e9af";
}
.icon-paw:before {
  content: "\e9b0";
}
.icon-slider:before {
  content: "\e9b1";
}
.icon-bullhorn:before {
  content: "\e9b2";
}
.icon-icon-24:before {
  content: "\e9b3";
}
.icon-desktop:before {
  content: "\e9b4";
}
.icon-responsive:before {
  content: "\e9b5";
}
.icon-gif:before {
  content: "\e9b6";
}
.icon-icon-25:before {
  content: "\e9b7";
}
.icon-paperclip:before {
  content: "\e9b8";
}
.icon-print:before {
  content: "\e9b9";
}
.icon-briefcase:before {
  content: "\e9ba";
}
.icon-icon-26:before {
  content: "\e9bb";
}
.icon-arrow:before {
  content: "\e9bc";
}
.icon-list-alt:before {
  content: "\e9bd";
}
.icon-usb:before {
  content: "\e9be";
}
.icon-headphone:before {
  content: "\e9bf";
}
.icon-icon-27:before {
  content: "\e9c0";
}
.icon-certificated:before {
  content: "\e9c1";
}
.icon-discount:before {
  content: "\e9c2";
}
.icon-cloud-rain:before {
  content: "\e9c3";
}
.icon-sun-alt:before {
  content: "\e9c4";
}
.icon-temperature:before {
  content: "\e9c5";
}
.icon-wind:before {
  content: "\e9c6";
}
.icon-moon:before {
  content: "\e9c7";
}
.icon-snowflake:before {
  content: "\e9c8";
}
.icon-not-cloud:before {
  content: "\e9c9";
}
.icon-icon-28:before {
  content: "\e9ca";
}
.icon-stop:before {
  content: "\e9cb";
}
.icon-icon-29:before {
  content: "\e9cc";
}
.icon-icon-30:before {
  content: "\e9cd";
}
.icon-lamp:before {
  content: "\e9ce";
}
.icon-globe-stand:before {
  content: "\e9cf";
}
.icon-keyboard:before {
  content: "\e9d0";
}
.icon-typography:before {
  content: "\e9d1";
}
.icon-bug:before {
  content: "\e9d2";
}
.icon-calendar-alt:before {
  content: "\e9d3";
}
.icon-phone-calendar:before {
  content: "\e9d4";
}
.icon-satelite:before {
  content: "\e9d5";
}
.icon-hand:before {
  content: "\e9d6";
}
.icon-icon-31:before {
  content: "\e9d7";
}
.icon-network:before {
  content: "\e9d8";
}
.icon-icon-32:before {
  content: "\e9d9";
}
.icon-swatchbook:before {
  content: "\e9da";
}
.icon-dolar:before {
  content: "\e9db";
}
.icon-bitcoin:before {
  content: "\e9dc";
}
.icon-icon-33:before {
  content: "\e9dd";
}
.icon-icon-34:before {
  content: "\e9de";
}
.icon-icon-35:before {
  content: "\e9df";
}
.icon-eye-dropper:before {
  content: "\e9e0";
}
.icon-icon-36:before {
  content: "\e9e1";
}
.icon-double-bookmark:before {
  content: "\e9e2";
}
.icon-pen-nib:before {
  content: "\e9e3";
}
.icon-magic:before {
  content: "\e9e4";
}
.icon-fire:before {
  content: "\e9e5";
}
.icon-water:before {
  content: "\e9e6";
}
.icon-icon-37:before {
  content: "\e9e7";
}
.icon-umbrella:before {
  content: "\e9e8";
}
.icon-icon-38:before {
  content: "\e9e9";
}
.icon-icon-39:before {
  content: "\e9ea";
}
.icon-flag-2:before {
  content: "\e9eb";
}
.icon-icon-40:before {
  content: "\e9ec";
}
.icon-icon-41:before {
  content: "\e9ed";
}
.icon-leaf:before {
  content: "\e9ee";
}
.icon-car:before {
  content: "\e9ef";
}
.icon-icon-42:before {
  content: "\e9f0";
}
.icon-icon-43:before {
  content: "\e9f1";
}
.icon-icon-44:before {
  content: "\e9f2";
}
.icon-icon-45:before {
  content: "\e9f3";
}
.icon-icon-46:before {
  content: "\e9f4";
}
.icon-icon-47:before {
  content: "\e9f5";
}
.icon-icon-48:before {
  content: "\e9f6";
}
.icon-icon-49:before {
  content: "\e9f7";
}
.icon-icon-50:before {
  content: "\e9f8";
}
.icon-icon-51:before {
  content: "\e9f9";
}
.icon-icon-52:before {
  content: "\e9fa";
}
.icon-icon-53:before {
  content: "\e9fb";
}
.icon-icon-54:before {
  content: "\e9fc";
}
.icon-whatsapp:before {
  content: "\e9fd";
}
.icon-facebook:before {
  content: "\e9fe";
}
